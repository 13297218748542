/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AjaxResponse } from '../models/ajax-response';
import { AjaxResponseOfListOfProviderSettingsProfileDto } from '../models/ajax-response-of-list-of-provider-settings-profile-dto';
import { AjaxResponseOfProviderSettingsEditDto } from '../models/ajax-response-of-provider-settings-edit-dto';
import { AjaxResponseOfProviderSettingsProfileAssociationDto } from '../models/ajax-response-of-provider-settings-profile-association-dto';
import { AjaxResponseOfProviderSettingsProfileDto } from '../models/ajax-response-of-provider-settings-profile-dto';
import { AjaxResponseOfString } from '../models/ajax-response-of-string';
import { ProviderSettingsEditDto } from '../models/provider-settings-edit-dto';
import { ProviderSettingsProfileAssociationDto } from '../models/provider-settings-profile-association-dto';
import { ProviderSettingsProfileDto } from '../models/provider-settings-profile-dto';

@Injectable({
  providedIn: 'root',
})
export class ProviderSettingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllConfigurationSettings
   */
  static readonly GetAllConfigurationSettingsPath = '/api/services/app/ProviderSettings/GetAllConfigurationSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllConfigurationSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllConfigurationSettings$Response(params?: {
  }): Observable<StrictHttpResponse<AjaxResponseOfProviderSettingsEditDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSettingsService.GetAllConfigurationSettingsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfProviderSettingsEditDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllConfigurationSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllConfigurationSettings(params?: {
  }): Observable<AjaxResponseOfProviderSettingsEditDto> {

    return this.getAllConfigurationSettings$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfProviderSettingsEditDto>) => r.body as AjaxResponseOfProviderSettingsEditDto)
    );
  }

  /**
   * Path part for operation updateAllSettingsProfile
   */
  static readonly UpdateAllSettingsProfilePath = '/api/services/app/ProviderSettings/UpdateAllSettingsProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAllSettingsProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAllSettingsProfile$Response(params?: {
    tenantId?: number;
    body?: ProviderSettingsEditDto
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSettingsService.UpdateAllSettingsProfilePath, 'put');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAllSettingsProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAllSettingsProfile(params?: {
    tenantId?: number;
    body?: ProviderSettingsEditDto
  }): Observable<AjaxResponse> {

    return this.updateAllSettingsProfile$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * Path part for operation getAllSettingsProfiles
   */
  static readonly GetAllSettingsProfilesPath = '/api/services/app/ProviderSettings/GetAllSettingsProfiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSettingsProfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSettingsProfiles$Response(params?: {
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfProviderSettingsProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSettingsService.GetAllSettingsProfilesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfProviderSettingsProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllSettingsProfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSettingsProfiles(params?: {
  }): Observable<AjaxResponseOfListOfProviderSettingsProfileDto> {

    return this.getAllSettingsProfiles$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfProviderSettingsProfileDto>) => r.body as AjaxResponseOfListOfProviderSettingsProfileDto)
    );
  }

  /**
   * Path part for operation getSettingsProfile
   */
  static readonly GetSettingsProfilePath = '/api/services/app/ProviderSettings/GetSettingsProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingsProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsProfile$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfProviderSettingsProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSettingsService.GetSettingsProfilePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfProviderSettingsProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSettingsProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsProfile(params?: {
    id?: string;
  }): Observable<AjaxResponseOfProviderSettingsProfileDto> {

    return this.getSettingsProfile$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfProviderSettingsProfileDto>) => r.body as AjaxResponseOfProviderSettingsProfileDto)
    );
  }

  /**
   * Path part for operation createProfile
   */
  static readonly CreateProfilePath = '/api/services/app/ProviderSettings/CreateProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createProfile$Response(params?: {
    body?: ProviderSettingsProfileDto
  }): Observable<StrictHttpResponse<AjaxResponseOfProviderSettingsProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSettingsService.CreateProfilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfProviderSettingsProfileDto>;
      })
    ); 
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createProfile(params?: {
    body?: ProviderSettingsProfileDto
  }): Observable<AjaxResponseOfProviderSettingsProfileDto> {

    return this.createProfile$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfProviderSettingsProfileDto>) => r.body as AjaxResponseOfProviderSettingsProfileDto)
    );
  }

  /**
   * Path part for operation updateSettingsProfile
   */
  static readonly UpdateSettingsProfilePath = '/api/services/app/ProviderSettings/UpdateSettingsProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSettingsProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateSettingsProfile$Response(params?: {
    body?: ProviderSettingsProfileDto
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSettingsService.UpdateSettingsProfilePath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSettingsProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateSettingsProfile(params?: {
    body?: ProviderSettingsProfileDto
  }): Observable<AjaxResponse> {

    return this.updateSettingsProfile$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * Path part for operation getAllAssociations
   */
  static readonly GetAllAssociationsPath = '/api/services/app/ProviderSettings/GetAllAssociations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAssociations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAssociations$Response(params?: {
  }): Observable<StrictHttpResponse<AjaxResponseOfProviderSettingsProfileAssociationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSettingsService.GetAllAssociationsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfProviderSettingsProfileAssociationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllAssociations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAssociations(params?: {
  }): Observable<AjaxResponseOfProviderSettingsProfileAssociationDto> {

    return this.getAllAssociations$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfProviderSettingsProfileAssociationDto>) => r.body as AjaxResponseOfProviderSettingsProfileAssociationDto)
    );
  }

  /**
   * Path part for operation associateProfiles
   */
  static readonly AssociateProfilesPath = '/api/services/app/ProviderSettings/AssociateProfiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `associateProfiles()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  associateProfiles$Response(params?: {
    body?: Array<ProviderSettingsProfileAssociationDto>
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSettingsService.AssociateProfilesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `associateProfiles$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  associateProfiles(params?: {
    body?: Array<ProviderSettingsProfileAssociationDto>
  }): Observable<AjaxResponse> {

    return this.associateProfiles$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * Path part for operation applyProfiles
   */
  static readonly ApplyProfilesPath = '/api/services/app/ProviderSettings/ApplyProfiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applyProfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  applyProfiles$Response(params?: {
    tenantId?: number;
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSettingsService.ApplyProfilesPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `applyProfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  applyProfiles(params?: {
    tenantId?: number;
  }): Observable<AjaxResponse> {

    return this.applyProfiles$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * Path part for operation deleteProfile
   */
  static readonly DeleteProfilePath = '/api/services/app/ProviderSettings/DeleteProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProfile$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSettingsService.DeleteProfilePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProfile(params?: {
    id?: string;
  }): Observable<AjaxResponse> {

    return this.deleteProfile$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

}
